import {createStore} from 'vuex'
import modules from './modules/index.js'
import axios from "axios"

let token_name = 'jwt_token'

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem(token_name);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const store = createStore({
    modules,
    state: {
        user: {
            id: 1,
            full_name: '',
            email: '',
            role: [],
            isAuthenticated: false,
            errors: [],
            company: {}
        },
        year: ''
    },
    mutations: {
        setUser(state, user) {
            state.user = {
                id: user.id,
                full_name: user.full_name,
                username: user.username,
                email: user.email,
                role: user.role,
                isAuthenticated: true,
                pages: user.pages || [],
                company: user.company || {}
            }
        },
        setError(state, message) {
            state.user.errors = []
            state.user.errors.push(message)
        },
        clearError(state) {
            state.user.errors = []
        },
        logout(state) {
            localStorage.removeItem(token_name)
            localStorage.removeItem('refresh_token')
            state.user = {
                id: 0,
                full_name: '',
                email: '',
                role: null,
                isAuthenticated: false,
                errors: [],
                company: {}
            }
        },
        setYear(state, year) {
            if (year || year === "") {
                localStorage.setItem('systemYear', year)
            } else {
                localStorage.setItem('systemYear', "")
            }
            state.year = year
        }
    },
    actions: {
        setUser({commit}, user) {
            commit('setUser', user)
        },
        setYear({commit}, year) {
            commit('setYear', year)
        },
        clearError({commit}) {
            commit('clearError')
        },
        async login({commit}, {username, password}) {
            try {
                const response = await axios.post('/user/login/', {
                    username: username,
                    password: password,
                });
                localStorage.setItem(token_name, response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                commit('clearError');
            } catch (e) {
                let error_msg = 'Could not log you in. Please try again later.';
                if (e.response && e.response.data && e.response.data.message) {
                    error_msg = e.response.data.message;
                }
                commit('setError', error_msg);
            }
        },
        async verifyToken({commit}) {
            const token = localStorage.getItem(token_name);
            if (token) {
                try {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    const response = await axios.post('/user/verify/');
                    localStorage.setItem('token_exp', response.data.exp);
                    commit('setUser', response.data.user);
                } catch {
                    // trying to refresh the token
                    try {
                        const refresh_token = localStorage.getItem('refresh_token');
                        if (!refresh_token) {
                            commit('logout');
                            return;
                        }
                        const response = await axios.post('/user/refresh/', {refresh: refresh_token});
                        localStorage.setItem(token_name, response.data.access);
                        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;
                        const res = await axios.post('/user/verify/');
                        localStorage.setItem('token_exp', res.data.exp);
                        commit('setUser', res.data.user);
                    } catch {
                        commit('logout');
                    }
                }
            } else {
                commit('logout');
            }
        },
        logout({commit}) {
            commit('logout')
        }
    },
})
//
// function requiresCSRFToken(method) {
//     return /^(POST|PUT)$/i.test(method);
// }

export default store

