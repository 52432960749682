import {createWebHistory, createRouter} from "vue-router";
import routes from './routes'
import store from "@/state/store"

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
})


router.beforeEach(async (to, routeFrom, next) => {
    try {
        const userStore = store

        if (to.meta.authRequired) {
            const currentTimestamp = Date.now();
            const timestampInSeconds = Math.floor(currentTimestamp / 1000);

            if (!localStorage.getItem('token_exp') || timestampInSeconds >= (localStorage.getItem('token_exp') || 0)) {
                await userStore.dispatch('verifyToken')
            }

            if (!userStore.state.user.isAuthenticated) {
                await userStore.dispatch('verifyToken')
            }

            if (userHasAccess(to, userStore.state.user)) {
                next();
            } else {
                if (userStore.state.user.isAuthenticated) {
                    await router.push({name: 'forbidden'});
                } else {
                    await router.push({name: 'login', query: {redirect: to.fullPath}});
                }
            }
        } else {
            next();
        }

        document.title = `${to.meta.title} - InterRail`;

    } catch (e) {
        alert('error while loading the page :(')
    }
})

function userHasAccess(to, user) {
    if (user && user.isAuthenticated) {
        return (user.pages || []).includes(to.name)
    }
    return false
}

export default router

