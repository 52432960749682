import axios from "axios"

export const state = {
    orders_lists: [],
    currentlyUpdating: [],

    lot_number: '',
    sending_type: '',
    position: '',
    shipper: '',
    consignee: '',
    order_type: '',
    shipment_status: '',
    payment_status: '',
    date: '',
    departure_id: '',
    destination_id: '',
    border_crossing: '',
    conditions_of_carriage: '',
    rolling_stock: '',
    departure: '',
    destination: '',
    company_id: '',
    product_id: '',
    comment: '',

    request_status: 'initial',
    is_pre_order: false,

    counterparties: [],

    container_types: [
        {
            type: '40HC',
            quantity: '',
            agreed_rate: '',
            counterparties: []
        },
    ],

    autocomplete: {
        departures: null,
        destinations: null,
        products: null,
        companies: null,
        order_number: null,
    },

    pre_order: {
        id: '',
        type: '',
    }
};

export const mutations = {
    setOrders(state, newValue) {
        state.orders = newValue
    },
    setUpdatingOrder(state, newValue) {
        state.currentlyUpdating = newValue
    },
    updateUpdatingOrder(state, newValue) {
        state.currentlyUpdating = newValue
    },
    setLotNumber(state, newValue) {
        state.lot_number = newValue
    },
    setSendingType(state, newValue) {
        state.sending_type = newValue
    },
    setPosition(state, newValue) {
        state.position = newValue
    },
    setShipper(state, newValue) {
        state.shipper = newValue
    },
    setConsignee(state, newValue) {
        state.consignee = newValue
    },
    setOrderType(state, newValue) {
        state.order_type = newValue
    },
    setShipmentStatus(state, newValue) {
        state.shipment_status = newValue
    },
    setPaymentStatus(state, newValue) {
        state.payment_status = newValue
    },
    setDate(state, newValue) {
        state.date = newValue
    },
    setDepartureId(state, newValue) {
        state.departure_id = newValue
    },
    setDestinationId(state, newValue) {
        state.destination_id = newValue
    },
    setBorderCrossing(state, newValue) {
        state.border_crossing = newValue
    },
    setConditionsOfCarriage(state, newValue) {
        state.conditions_of_carriage = newValue
    },
    setRollingStock(state, newValue) {
        state.rolling_stock = newValue
    },
    setDeparture(state, newValue) {
        state.departure = newValue
    },
    setDestination(state, newValue) {
        state.destination = newValue
    },
    setCompanyId(state, newValue) {
        state.company_id = newValue
    },
    setProductId(state, newValue) {
        state.product_id = newValue
    },
    setComment(state, newValue) {
        state.comment = newValue
    },
    setPreOrder(state, newValue) {
        state.pre_order = newValue
    },
    setRequestStatus(state, request_status) {
        state.request_status = request_status
    },
    setIsPreOrder(state, is_pre_order) {
        state.is_pre_order = is_pre_order
    },

    addCounterparty(state, counterparty) {
        let newCounterparty = {...counterparty}
        if (state.pre_order && state.pre_order.type === 'code_order') {
            state.counterparties.push({
                counterparty: newCounterparty.counterparty,
                categories: [...newCounterparty.categories].map(category => {
                    return {
                        ...category
                    }
                }),
                delete_is_confirmed: newCounterparty.delete_is_confirmed,
                territories: [...newCounterparty.territories].map(territory => {
                    return {
                        ...territory
                    }
                }),
            });
        } else {
            state.counterparties.push({
                counterparty: newCounterparty.counterparty,
                categories: [...newCounterparty.categories].map(category => {
                    return {
                        ...category
                    }
                }),
                delete_is_confirmed: newCounterparty.delete_is_confirmed,
            });
        }
        state.container_types.forEach(type => {
            type.counterparties.push({
                counterparty: newCounterparty.counterparty,
                categories: [...newCounterparty.categories].map(category => {
                    return {
                        ...category
                    }
                }),
                delete_is_confirmed: newCounterparty.delete_is_confirmed
            })
        })
    },
    removeCounterparty(state, index) {
        state.counterparties.splice(index, 1);
        state.container_types.forEach(type => {
            type.counterparties.splice(index, 1);
        })
    },

    addContainerType(state, container_type) {
        let newContainerType = {...container_type}
        state.counterparties.forEach(counterparty => {
            newContainerType.counterparties.push({
                counterparty: {...counterparty.counterparty},
                categories: [...counterparty.categories].map(category => {
                    return {
                        ...category,
                        price: category.price ? category.price : ''
                    }
                })
            })
        })
        state.container_types.push(newContainerType);
    },
    removeContainerType(state, container_type) {
        state.container_types = state.container_types.filter(t => t.type !== container_type)
    },

    setAutoComplete(state, autocomplete) {
        state.autocomplete = autocomplete
    },

    fullClean(state) {
        state.lot_number = ''
        state.lot_number = ''
        state.sending_type = ''
        state.position = ''
        state.shipper = ''
        state.consignee = ''
        state.order_type = ''
        state.shipment_status = ''
        state.payment_status = ''
        state.date = ''
        state.departure_id = ''
        state.destination_id = ''
        state.border_crossing = ''
        state.conditions_of_carriage = ''
        state.rolling_stock = ''
        state.departure = ''
        state.destination = ''
        state.company_id = ''
        state.product_id = ''
        state.comment = ''
        state.counterparties = []
        state.container_types = [
            {
                type: '40HC',
                quantity: '',
                agreed_rate: '',
                counterparties: []
            },
        ]
        state.autocomplete = {
            departures: null,
            destinations: null,
            products: null,
            companies: null,
            order_number: null,
        }
        state.request_status = 'initial'
        state.is_pre_order = false
        state.pre_order = {
            id: '',
            type: ''
        }
    }

};

export const actions = {
    setCurrentlyUpdating({commit}, order) {
        commit('setUpdatingOrder', order)
    },
    async updateCurrentUpdating({commit}, order_data) {
        let order = order_data['order']
        let update_order_type = order_data['type']

        let order_payment_status = order.payment_status.toLowerCase()

        let order_position = order.position.toLowerCase().replace(' ', '_')

        let order_shipment_status = order.shipment_status.toLowerCase().replace(' ', '_')

        let order_type = order.type.toLowerCase()

        let thisorder = {
            lot_number: order.lot_number,
            date: order.date,
            position: order_position,
            type: order_type,
            shipment_status: order_shipment_status,
            payment_status: order_payment_status,
            shipper: order.shipper,
            consignee: order.consignee,
            departure_id: order.departure_id,
            destination_id: order.destination_id,
            border_crossing: order.border_crossing,
            conditions_of_carriage: order.conditions_of_carriage,
            rolling_stock: order.rolling_stock,
            departure_country: order.departure_country,
            destination_country: order.destination_country,
            comment: order.comment,
            manager: order.manager,
            company_id: order.company_id ? order.company_id : order.company.id,
        }

        let return_response = ''

        if (update_order_type === 'wagon') {
            let product_id = order_data['product']
            let quantity = order_data['quantity']
            let response = await axios.put(`/wagon_order/update/${order.order_number}/`, {
                order: thisorder,
                product_id: product_id,
                quantity: quantity
            });
            return_response = response
        } else if (update_order_type === 'container') {
            let product_id = order_data['product']
            let sending_type = order_data['sending_type']
            let response = await axios.put(`/container_order/list/${order.order_number}/edit/`, {
                order: thisorder,
                sending_type: sending_type,
                product_id: product_id
            });
            return_response = response
        } else {
            let response = await axios.put(`/wagon_empty_order/update/${order.order_number}/`, {
                order: thisorder,
                quantity: order_data['quantity'],
            });
            return_response = response
        }
        commit('setUpdatingOrder', order)
        return return_response
    },

    setLotNumber({commit}, lot_number) {
        commit('setLotNumber', lot_number)
    },
    setSendingType({commit}, sending_type) {
        commit('setSendingType', sending_type)
    },
    setPosition({commit}, position) {
        commit('setPosition', position)
    },
    setShipper({commit}, shipper) {
        commit('setShipper', shipper)
    },
    setConsignee({commit}, consignee) {
        commit('setConsignee', consignee)
    },
    setOrderType({commit}, order_type) {
        commit('setOrderType', order_type)
    },
    setShipmentStatus({commit}, shipment_status) {
        commit('setShipmentStatus', shipment_status)
    },
    setPaymentStatus({commit}, payment_status) {
        commit('setPaymentStatus', payment_status)
    },
    setDate({commit}, date) {
        commit('setDate', date)
    },
    setDepartureId({commit}, departure_id) {
        commit('setDepartureId', departure_id)
    },
    setDestinationId({commit}, destination_id) {
        commit('setDestinationId', destination_id)
    },
    setBorderCrossing({commit}, border_crossing) {
        commit('setBorderCrossing', border_crossing)
    },
    setConditionsOfCarriage({commit}, conditions_of_carriage) {
        commit('setConditionsOfCarriage', conditions_of_carriage)
    },
    setRollingStock({commit}, rolling_stock) {
        commit('setRollingStock', rolling_stock)
    },
    setDeparture({commit}, departure) {
        commit('setDeparture', departure)
    },
    setDestination({commit}, destination) {
        commit('setDestination', destination)
    },
    setCompanyId({commit}, company_id) {
        commit('setCompanyId', company_id)
    },
    setProductId({commit}, product_id) {
        commit('setProductId', product_id)
    },
    setComment({commit}, comment) {
        commit('setComment', comment)
    },
    setPreOrder({commit}, pre_order) {
        commit('setPreOrder', pre_order)
    },
    setRequestStatus({commit}, comment) {
        commit('setRequestStatus', comment)
    },
    setIsPreOrder({commit}, is_pre_order) {
        commit('setIsPreOrder', is_pre_order)
    },
    addCounterparty({commit}, counterparty) {
        commit('addCounterparty', counterparty)
    },
    removeCounterparty({commit}, index) {
        commit('removeCounterparty', index)
    },

    setContainerTypes({commit}, counterparty) {
        commit('setContainerTypes', counterparty)
    },
    addContainerType({commit}, counterparty) {
        commit('addContainerType', counterparty)
    },
    removeContainerType({commit}, name) {
        commit('removeContainerType', name)
    },

    setAutoComplete({commit}, autocomplete) {
        commit('setAutoComplete', autocomplete)
    },

    fullClean({commit}) {
        commit('fullClean')
    }
};

export const getters = {
    orders_list: state => state.orders_lists,

    lot_number: state => state.lot_number,
    sending_type: state => state.sending_type,
    position: state => state.position,
    shipper: state => state.shipper,
    consignee: state => state.consignee,
    order_type: state => state.order_type,
    shipment_status: state => state.shipment_status,
    payment_status: state => state.payment_status,
    date: state => state.date,
    departure_id: state => state.departure_id,
    destination_id: state => state.destination_id,
    border_crossing: state => state.border_crossing,
    conditions_of_carriage: state => state.conditions_of_carriage,
    rolling_stock: state => state.rolling_stock,
    departure: state => state.departure,
    destination: state => state.destination,
    company_id: state => state.company_id,
    product_id: state => state.product_id,
    comment: state => state.comment,
    pre_order: state => state.pre_order,

    autocomplete: state => state.autocomplete,

    counterparties: state => state.counterparties,
    request_status: state => state.request_status,
    is_pre_order: state => state.is_pre_order,
};