export const state = {
    lot_number: '',
    position: '',
    shipper: '',
    consignee: '',
    order_type: '',
    shipment_status: '',
    payment_status: '',
    date: '',
    departure_id: '',
    destination_id: '',
    border_crossing: '',
    conditions_of_carriage: '',
    rolling_stock: '',
    departure: '',
    destination: '',
    company_id: '',
    comment: '',

    quantity: '',
    additional_cost: '',
    agreed_rate: '',

    request_status: 'initial',
    is_pre_order: false,

    pre_order: {
        id: '',
        type: ''
    },

    counterparties: [],

    autocomplete: {
        departures: null,
        destinations: null,
        companies: null,
        order_number: null,
    }
};

export const mutations = {
    setLotNumber(state, newValue) {
        state.lot_number = newValue
    },
    setPosition(state, newValue) {
        state.position = newValue
    },
    setShipper(state, newValue) {
        state.shipper = newValue
    },
    setConsignee(state, newValue) {
        state.consignee = newValue
    },
    setOrderType(state, newValue) {
        state.order_type = newValue
    },
    setShipmentStatus(state, newValue) {
        state.shipment_status = newValue
    },
    setPaymentStatus(state, newValue) {
        state.payment_status = newValue
    },
    setDate(state, newValue) {
        state.date = newValue
    },
    setDepartureId(state, newValue) {
        state.departure_id = newValue
    },
    setDestinationId(state, newValue) {
        state.destination_id = newValue
    },
    setBorderCrossing(state, newValue) {
        state.border_crossing = newValue
    },
    setConditionsOfCarriage(state, newValue) {
        state.conditions_of_carriage = newValue
    },
    setRollingStock(state, newValue) {
        state.rolling_stock = newValue
    },
    setDeparture(state, newValue) {
        state.departure = newValue
    },
    setDestination(state, newValue) {
        state.destination = newValue
    },
    setCompanyId(state, newValue) {
        state.company_id = newValue
    },
    setComment(state, newValue) {
        state.comment = newValue
    },

    setQuantity(state, quantity) {
        state.quantity = quantity
    },

    setAdditionalCost(state, additional_cost) {
        state.additional_cost = additional_cost
    },
    setAgreedRate(state, agreed_rate) {
        state.agreed_rate = agreed_rate
    },

    setPreOrder(state, newValue) {
        state.pre_order = newValue
    },

    setRequestStatus(state, request_status) {
        state.request_status = request_status
    },
    setIsPreOrder(state, is_pre_order) {
        state.is_pre_order = is_pre_order
    },

    addCounterparty(state, counterparty) {
        let newCounterparty = {...counterparty}
        if (state.pre_order && state.pre_order.type === 'code_order') {
            state.counterparties.push({
                counterparty: newCounterparty.counterparty,
                categories: [...newCounterparty.categories].map(category => {
                    return {
                        ...category
                    }
                }),
                territories: [...newCounterparty.territories].map(territory => {
                    return {
                        ...territory
                    }
                }),
                delete_is_confirmed: newCounterparty.delete_is_confirmed
            });
        } else {
            state.counterparties.push({
                counterparty: newCounterparty.counterparty,
                categories: [...newCounterparty.categories].map(category => {
                    return {
                        ...category
                    }
                }),
                delete_is_confirmed: newCounterparty.delete_is_confirmed
            });
        }
    },
    removeCounterparty(state, index) {
        state.counterparties.splice(index, 1);
    },

    setAutoComplete(state, autocomplete) {
        state.autocomplete = autocomplete
    },

    fullClean(state) {
        state.lot_number = ''
        state.position = ''
        state.shipper = ''
        state.consignee = ''
        state.order_type = ''
        state.shipment_status = ''
        state.payment_status = ''
        state.date = ''
        state.departure_id = ''
        state.destination_id = ''
        state.border_crossing = ''
        state.conditions_of_carriage = ''
        state.rolling_stock = ''
        state.departure = ''
        state.destination = ''
        state.company_id = ''
        state.comment = ''
        state.quantity = ''
        state.additional_cost = ''

        state.counterparties = []

        state.autocomplete = {
            departures: null,
            destinations: null,
            companies: null,
            order_number: null,
        }

        state.request_status = 'initial'
        state.is_pre_order = false
        state.pre_order = {
            id: '',
            type: ''
        }
    }

};

export const actions = {
    setLotNumber({commit}, lot_number) {
        commit('setLotNumber', lot_number)
    },
    setPosition({commit}, position) {
        commit('setPosition', position)
    },
    setShipper({commit}, shipper) {
        commit('setShipper', shipper)
    },
    setConsignee({commit}, consignee) {
        commit('setConsignee', consignee)
    },
    setOrderType({commit}, order_type) {
        commit('setOrderType', order_type)
    },
    setShipmentStatus({commit}, shipment_status) {
        commit('setShipmentStatus', shipment_status)
    },
    setPaymentStatus({commit}, payment_status) {
        commit('setPaymentStatus', payment_status)
    },
    setDate({commit}, date) {
        commit('setDate', date)
    },
    setDepartureId({commit}, departure_id) {
        commit('setDepartureId', departure_id)
    },
    setDestinationId({commit}, destination_id) {
        commit('setDestinationId', destination_id)
    },
    setBorderCrossing({commit}, border_crossing) {
        commit('setBorderCrossing', border_crossing)
    },
    setConditionsOfCarriage({commit}, conditions_of_carriage) {
        commit('setConditionsOfCarriage', conditions_of_carriage)
    },
    setRollingStock({commit}, rolling_stock) {
        commit('setRollingStock', rolling_stock)
    },
    setDeparture({commit}, departure) {
        commit('setDeparture', departure)
    },
    setDestination({commit}, destination) {
        commit('setDestination', destination)
    },
    setCompanyId({commit}, company_id) {
        commit('setCompanyId', company_id)
    },
    setComment({commit}, comment) {
        commit('setComment', comment)
    },

    setQuantity({commit}, quantity) {
        commit('setQuantity', quantity)
    },

    setAdditionalCost({commit}, additional_cost) {
        commit('setAdditionalCost', additional_cost)
    },

    setAgreedRate({commit}, agreed_rate) {
        commit('setAgreedRate', agreed_rate)
    },

    setPreOrder({commit}, pre_order) {
        commit('setPreOrder', pre_order)
    },

    setRequestStatus({commit}, comment) {
        commit('setRequestStatus', comment)
    },
    setIsPreOrder({commit}, is_pre_order) {
        commit('setIsPreOrder', is_pre_order)
    },
    addCounterparty({commit}, counterparty) {
        commit('addCounterparty', counterparty)
    },
    removeCounterparty({commit}, index) {
        commit('removeCounterparty', index)
    },

    setAutoComplete({commit}, autocomplete) {
        commit('setAutoComplete', autocomplete)
    },

    fullClean({commit}) {
        commit('fullClean')
    }
};

export const getters = {
    lot_number: state => state.lot_number,
    position: state => state.position,
    shipper: state => state.shipper,
    consignee: state => state.consignee,
    order_type: state => state.order_type,
    shipment_status: state => state.shipment_status,
    payment_status: state => state.payment_status,
    date: state => state.date,
    departure_id: state => state.departure_id,
    destination_id: state => state.destination_id,
    border_crossing: state => state.border_crossing,
    conditions_of_carriage: state => state.conditions_of_carriage,
    rolling_stock: state => state.rolling_stock,
    departure: state => state.departure,
    destination: state => state.destination,
    company_id: state => state.company_id,
    comment: state => state.comment,
    quantity: state => state.quantity,
    additional_cost: state => state.additional_cost,
    agreed_rate: state => state.agreed_rate,

    autocomplete: state => state.autocomplete,

    counterparties: state => state.counterparties,
    request_status: state => state.request_status,
    is_pre_order: state => state.is_pre_order,
    pre_order: state => state.pre_order,
};